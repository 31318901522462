<template>
  <div class="myCustomer-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>客户名称:</span>
        <el-input class="common-screen-input" placeholder="请输入"></el-input>
      </div>
      <div class="common-input-container">
        <span>公海类型:</span>
        <el-select
          class="common-screen-input"
          v-model="roleSelect"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>客户级别:</span>
        <el-select
          class="common-screen-input"
          v-model="roleSelect"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span class="time">创建时间:</span>
        <el-date-picker
          v-model="roleSelect"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <el-button class="common-screen-btn" type="primary">查 询</el-button>
      <el-button class="common-screen-btn" plain>重 置</el-button>
    </div>
    <el-table class="common-table" :data="tableData">
      <el-table-column prop="date" label="客户名称"></el-table-column>
      <el-table-column prop="name" label="所属公海"></el-table-column>
      <el-table-column prop="province" label="客户级别"></el-table-column>
      <el-table-column prop="city" label="客户来源"></el-table-column>
      <el-table-column prop="address" label="成单次数"></el-table-column>
      <el-table-column prop="address" label="行业"></el-table-column>
      <el-table-column prop="address" label="来源"></el-table-column>
      <el-table-column prop="address" label="负责人"></el-table-column>
      <el-table-column prop="address" label="最近跟进时间"></el-table-column>
      <el-table-column prop="address" label="备注"></el-table-column>
      <el-table-column prop="address" label="创建人"></el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="() => addDrawer(scope.row.date)"
            >查看详情/编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="100"
      layout="total, prev, pager, next,sizes, jumper"
      :total="400"
    >
    </el-pagination>
    <el-dialog
      title="修改密码"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="新密码" prop="name">
            <el-input
              class="dialog-input"
              v-model="ruleForm.name"
              maxLength="50"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <my-cust-edit
      :drawer="drawer"
      :tableRowId="tableRowId"
      @handleClose="handleClose"
    ></my-cust-edit>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import MyCustEdit from "./children/myCustEdit.vue";
export default {
  components: {
    Breadcrumb,
    MyCustEdit
  },
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "销售", isLink: false },
        { title: "我的客户", isLink: false }
      ],
      tableRowId: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 修改密码员工弹窗
      ruleForm: {
        // 修改密码
        name: ""
      },
      rules: {
        name: [{ required: true, message: "请填写密码", trigger: "change" }]
      },
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      options: [
        // 筛选下拉
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        }
      ],
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        }
      ]
    };
  },
  methods: {
    editPassword(e) {
      // 修改密码弹窗
      this.centerDialogVisible = true;
      console.log(e);
    },
    handleClose() {
      this.drawer = false;
      // 关闭新增员工弹窗
      // done();
    },
    addDrawer(id) {
      //新增员工
      this.drawer = true;
      if (id) {
        this.tableRowId = id;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.myCustomer-container {
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
.time {
  margin-right: 6px;
}
</style>
