<template>
  <div class="myCustEdit-container">
    <el-drawer
      class="myCustEdit-drawer"
      title="客户详情"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div class="drawer-container">
        <div class="detail-title">
          <div style="font-size: 18px">啊啊啊啊</div>
          <div>
            <el-button
              class="common-screen-btn"
              type="primary"
              @click="handleClose()"
              >编辑</el-button
            >
            <el-button
              class="common-screen-btn"
              type="primary"
              @click="handleClose()"
              >打回公海</el-button
            >
            <!-- <el-button class="common-screen-btn" @click="handleClose()"
              >删除</el-button
            > -->
          </div>
        </div>
        <div class="detail-address">暂无地址</div>
        <div class="detail-content">
          <div>客户级别: <span class="common-clr-0a6">额撒旦发顺丰</span></div>
          <div>电话: <span class="common-clr-0a6">额撒旦发顺丰</span></div>
          <div>负责人: <span class="common-clr-0a6">额撒旦发顺丰</span></div>
          <div>成交状态: <span class="common-clr-0a6">额撒旦发顺丰</span></div>
          <div>距上次跟进: <span class="common-clr-0a6">额撒旦丰</span></div>
        </div>
        <div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="相关" name="about">
              <div class="radioTab-container">
                <div
                  :class="
                    radioTab == '1'
                      ? 'radioTab-item radioTab-item-active'
                      : 'radioTab-item'
                  "
                  @click="onRadioTab('1')"
                >
                  跟进记录
                </div>
                <div
                  :class="
                    radioTab == '2'
                      ? 'radioTab-item radioTab-item-active'
                      : 'radioTab-item'
                  "
                  @click="onRadioTab('2')"
                >
                  销售机会
                </div>
                <div
                  :class="
                    radioTab == '3'
                      ? 'radioTab-item radioTab-item-active'
                      : 'radioTab-item'
                  "
                  @click="onRadioTab('3')"
                >
                  合同订单
                </div>
              </div>
              <div v-if="radioTab == '1'" class="about-content">
                跟进类型:
                <el-radio-group v-model="radio">
                  <el-radio label="1">电话拜访</el-radio>
                  <el-radio label="2">网络拜访</el-radio>
                  <el-radio label="3">上门拜访</el-radio>
                </el-radio-group>
                <el-input
                  style="margin-top: 16px"
                  :autosize="{ minRows: 6, maxRows: 6 }"
                  type="textarea"
                  v-model="ruleFormAdd.desc"
                ></el-input>
                <div style="margin-top: 16px">
                  <el-button class="common-screen-btn" type="primary">发布</el-button>
                </div>
                <div style="text-align: right">
                  <el-checkbox v-model="checkPlan"
                    >添加下次跟进计划</el-checkbox
                  >
                  <div v-if="checkPlan" class="nextPlan-container">
                    <div class="nextPlan-row">
                      跟进类型:
                      <el-radio-group v-model="radio">
                        <el-radio label="1">电话拜访</el-radio>
                        <el-radio label="2">网络拜访</el-radio>
                        <el-radio label="3">上门拜访</el-radio>
                      </el-radio-group>
                      <el-date-picker
                        class="nextPlan-dateP"
                        v-model="nextPlan.nextPlanDate"
                        type="date"
                        placeholder="选择日期"
                      >
                      </el-date-picker>
                    </div>
                    <div class="nextPlan-row">
                      跟进提醒:
                      <el-checkbox v-model="nextPlan.checkTime"
                        >距离计划开始前</el-checkbox
                      >
                      <el-select
                        class="nextPlan-select"
                        v-model="nextPlan.time"
                        placeholder="请选择"
                        filterable
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <span class="common-clr-606266"> 提醒一下</span>

                      <el-button
                        class="common-screen-btn"
                        style="margin-left: 120px"
                        type="primary"
                        >发布</el-button
                      >
                    </div>
                  </div>
                  <div class="SubmitPlan-container">
                    <div class="SubmitPlan-item">
                      <div class="item-row">
                        <span class="item-btn">计划</span>
                        2020-11-11
                      </div>
                      <div class="item-row">
                        <el-button class="item-user" type="text" size="small"
                          >admin
                        </el-button>
                        2020-11-11
                      </div>
                    </div>
                    <div class="SubmitPlan-item">
                      <div class="item-row">
                        <span class="item-btn">计划</span>
                        2020-11-11
                      </div>
                      <div class="item-row">
                        <el-button class="item-user" type="text" size="small"
                          >admin
                        </el-button>
                        2020-11-11
                      </div>
                    </div>
                    <div class="SubmitPlan-item">
                      <div class="item-row">
                        <span class="item-btn">计划</span>
                        2020-11-11
                      </div>
                      <div class="item-row">
                        <el-button class="item-user" type="text" size="small"
                          >admin
                        </el-button>
                        2020-11-11
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="radioTab == '2'">
                <el-table :data="tableData" stripe style="width: 100%">
                  <el-table-column prop="date" label="日期" width="180">
                  </el-table-column>
                  <el-table-column prop="name" label="姓名" width="180">
                  </el-table-column>
                  <el-table-column prop="address" label="地址">
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="资料" name="data">
              <div class="data-content">
                <div class="data-title">基本信息</div>
                <div class="detail-content">
                  <div>客户名称: <span class="common-clr-0a6">发顺</span></div>
                  <div>所属公海: <span class="common-clr-0a6">额丰</span></div>
                  <div>客户级别: <span class="common-clr-0a6">顺丰</span></div>
                  <div>客户行业: <span class="common-clr-0a6">顺丰</span></div>
                  <div>客户来源: <span class="common-clr-0a6">丰</span></div>
                </div>
              </div>
              <div class="data-content">
                <div class="data-title">联系信息</div>
                <div class="detail-content">
                  <div>详细地址: <span class="common-clr-0a6">发顺</span></div>
                  <div>电话: <span class="common-clr-0a6">额丰</span></div>
                  <div>微信: <span class="common-clr-0a6">顺丰</span></div>
                  <div>QQ: <span class="common-clr-0a6">顺丰</span></div>
                  <div>公司网址: <span class="common-clr-0a6">丰</span></div>
                  <div>邮箱: <span class="common-clr-0a6">丰</span></div>
                  <div>备注: <span class="common-clr-0a6">丰</span></div>
                </div>
              </div>
              <div class="data-content">
                <div class="data-title">系统信息</div>
                <div class="detail-content">
                  <div>负责人: <span class="common-clr-0a6">发顺</span></div>
                  <div>创建人: <span class="common-clr-0a6">额丰</span></div>
                  <div>所属部门: <span class="common-clr-0a6">顺丰</span></div>
                  <div>创建时间: <span class="common-clr-0a6">顺丰</span></div>
                  <div>最后修改人: <span class="common-clr-0a6">丰</span></div>
                  <div>
                    最后修改时间: <span class="common-clr-0a6">丰</span>
                  </div>
                </div>
              </div>
              <div class="data-content">
                <div class="data-title">跟进信息</div>
                <div class="detail-content">
                  <div>
                    最近跟进类型: <span class="common-clr-0a6">发顺</span>
                  </div>
                  <div>
                    最近跟进时间: <span class="common-clr-0a6">额丰</span>
                  </div>
                  <div>
                    最近跟进人: <span class="common-clr-0a6">顺丰</span>
                  </div>
                  <div>成交状态: <span class="common-clr-0a6">顺丰</span></div>
                  <div>创建时间: <span class="common-clr-0a6">丰</span></div>
                  <div>捞取时间: <span class="common-clr-0a6">丰</span></div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  props: ["drawer", "tableRowId"],
  data() {
    return {
      tableData: [
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      ruleFormAdd: {
        desc: "",
      }, // form提交数据
      nextPlan: {
        tiem: "",
        nextPlanDate: "",
        checkTime: false,
      }, // 下次跟进计划提交数据
      checkPlan: false, // 下次计划按钮
      activeName: "about", // tabs
      radioTab: "1",
      radio: "1",
      roleSelect: "",
      options: [
        // 筛选下拉
        {
          value: "5",
          label: "五分钟",
        },
        {
          value: "10",
          label: "10分钟",
        },
        {
          value: "30",
          label: "30分钟",
        },
        {
          value: "60",
          label: "60分钟",
        },
      ],
    };
  },
  methods: {
    handleClose(done) {
      this.$emit("handleClose", done);
    },
    handleClick() {
      console.log(this.activeName);
    },
    onRadioTab(value) {
      this.radioTab = value;
    },
  },
};
</script>
<style lang="less" scoped>
.myCustEdit-drawer {
  .drawer-container {
    padding: 0 24px;
    text-align: left;
    .SubmitPlan-container {
      margin-top: 24px;
      .SubmitPlan-item {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #797979;
        .item-row {
          display: flex;
          align-items: center;
          color: #999;
          font-size: 12px;
          .item-btn {
            height: 28px;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            border-radius: 2px;
            background: #1890ff;
            color: #fff;
            padding: 0 6px;
            margin: 10px 10px 14px;
          }
          .item-user {
            margin: 0 10px;
          }
        }
      }
    }
    .nextPlan-container {
      background: #f8f8f8;
      text-align: left;
      padding: 6px;
      margin: 24px 0;
      .nextPlan-row {
        height: 44px;
        line-height: 44px;
        .nextPlan-select {
          width: 120px;
          margin: 0 20px;
        }
        .nextPlan-dateP {
          width: 200px;
          margin-left: 28px;
        }
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .detail-content {
      div {
        display: inline-block;
        width: 30%;
        height: 21px;
        padding: 6px 0;
        font-size: 14px;
        color: #999;
      }
    }
    .detail-address {
      margin: 16px 0;
      color: #000000a6;
    }
    .about-content {
      padding-top: 16px;
    }
    .data-content {
      .detail-content {
        div,
        span {
          font-size: 12px;
        }
        div {
          width: 45%;
          height: 32px;
          line-height: 32px;
        }
      }
      .data-title {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        color: #000000a6;
        border-bottom: 1px dashed #ccc;
        margin-top: 16px;
      }
    }
    .radioTab-container {
      padding-top: 10px;
      .radioTab-item {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        padding: 0 16px;
        border-radius: 4px;
        border: 1px solid #ccc;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 16px;
        cursor: pointer;
        // border: 1px solid #1890ff;
      }
      .radioTab-item-active {
        border: 1px solid #1890ff;
        color: #1890ff;
      }
    }
  }

  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 45% !important;
  }
}
</style>